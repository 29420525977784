<template>
  <en-dialog :model-value="modelValue" title="库存台账" width="90%" @close="$emit('update:model-value', false)">
    <template #operation>
      <en-button type="primary" text>按门店导出</en-button>
      <en-button type="primary" text>按仓库导出</en-button>
      <en-button type="primary" text>按明细导出</en-button>
    </template>

    <en-form label-position="left" inline>
      <en-form-item label="统计日期">
        <en-date-picker v-model:start="query.data.startDate" v-model:end="query.data.endDate" type="daterange" @change="form.get"></en-date-picker>
      </en-form-item>
    </en-form>

    <div class="grid grid-cols-2 gap-6" v-loading="form.loading">
      <div class="col-span-2 border border-slate-200 p-3 flex justify-between">
        <span>期初</span>
        <span>数量：{{ formatNumber(form.data.beginningInventory?.count) }}</span>
        <span>金额：{{ formatMoney(form.data.beginningInventory?.amount) }}</span>
        <span>期末</span>
        <span>数量：{{ formatNumber(form.data.endingInventory?.count) }}</span>
        <span>金额：{{ formatMoney(form.data.endingInventory?.amount) }}</span>
      </div>

      <table class="w-full table-auto border border-slate-200 text-sm">
        <tbody>
          <tr class="bg-gray-100">
            <td class="p-3">入库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.in.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.in.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">库存初始化</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.initial.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.initial.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">采购入库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.stockIn.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.stockIn.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">采购退库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.stockInReturn.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.stockInReturn.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">其他入库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.stockInOther.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.stockInOther.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">盘点入库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.inventoryProfit.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.inventoryProfit.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">移库入库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.transferIn.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.transferIn.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">调价入库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.costAdjustmentIn.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.costAdjustmentIn.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">生产入库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.manufacturingIn.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.manufacturingIn.amount) }}</td>
          </tr>
        </tbody>
      </table>

      <table class="w-full table-auto border border-slate-200 text-sm">
        <tbody>
          <tr class="bg-gray-100">
            <td class="p-3">出库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.out.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.out.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">销售出库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.stockOut.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.stockOut.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">销售退库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.stockOutReturn.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.stockOutReturn.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">其他出库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.stockOutOther.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.stockOutOther.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">移库出库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.transferOut.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.transferOut.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">领料出库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.materialRequisition.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.materialRequisition.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">领料退库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.materialRequisitionReturn.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.materialRequisitionReturn.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">盘点出库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.inventoryLoss.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.inventoryLoss.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">调价出库</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.costAdjustmentOut.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.costAdjustmentOut.amount) }}</td>
          </tr>
          <tr>
            <td class="p-3">生产出库(领料)</td>
            <td class="p-3">数量：{{ formatNumber(form.data.stockSummary?.manufacturingOut.count) }}</td>
            <td class="p-3">金额：{{ formatMoney(form.data.stockSummary?.manufacturingOut.amount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  props: {
    modelValue: Boolean
  },

  watch: {
    modelValue: {
      handler() {
        if (this.modelValue) {
          this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      query: {
        data: {
          startDate: '',
          endDate: ''
        }
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/inventory/summary/dailystatement/summary',
            data: 'object',
            loading: true,
            params(params) {
              params.payload = this.query.data
            }
          }
        }
      }
    }
  }
})
</script>
